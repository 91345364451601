import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show_header_action: { display: false, title: '', mainAction: '', secondaryAction: '', loader: '', disabled: false, save_label: '' }
}

export const headerActionSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setShowHeaderAction: (state, action) => {
            state.show_header_action = action.payload
        }
    }
})

export const { setShowHeaderAction } = headerActionSlice.actions
export default headerActionSlice.reducer